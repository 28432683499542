<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Monte ore</h3>
            </div>

            <div class="col-md-6">

            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>



        <div class="top-content ">
            <p>Gestione del tempo disponibile degli studenti</p>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" width="40">#</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Residuo simulatore</th>
                            <th scope="col">Residuo guide</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId }"
                            v-for="item in availableTimes"
                            :key="item.id"
                            @click="setActiveAvailableTime(item)"
                        >
                            <td>{{ item.id }}</td>
                            <td>{{ item.student.firstname }} {{ item.student.lastname }}</td>
                            <td>{{ formatRemainingTime(item.secondsLeftSimulator) }}</td>
                            <td>{{ formatRemainingTime(item.secondsLeftPractice) }}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div v-if="currentId > 0">
                    <vehicle-edit :vehicle-id="currentAvailableTime.id" :key="currentAvailableTime.id"
                                  v-on:updated-vehicle="refreshCurrentAvailableTime($event)"
                                  v-on:deleted-vehicle="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <vehicle-create
                        v-on:created-vehicle="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import AvailableTimeDataService from "./AvailableTimeDataService";



export default {
    name: "available-times-index",
    components: {},
    data() {
        return {
            availableTimes: [],
            currentAvailableTime: null,
            currentId: -1,
        };
    },
    methods: {
        retrieveAvailableTimes() {
            AvailableTimeDataService.getAll()
                .then(response => {
                    this.availableTimes = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveAvailableTimes();
            this.currentAvailableTime = null;
            this.currentId = -1;
        },

        refreshCurrentAvailableTime(itemAvailableTime) {
            this.availableTimes.forEach(function (item) {
                if (item.id == itemAvailableTime.id) {
                    item.name = itemAvailableTime.name;
                    item.type = itemAvailableTime.type;
                    item.drivingLicenseId = itemAvailableTime.drivingLicenseId;
                    item.licensePlate = itemAvailableTime.licensePlate;
                }
            });
            this.currentAvailableTime = itemAvailableTime;
            this.currentId = itemAvailableTime.id;
        },

        setActiveAvailableTime(item) {
            this.currentAvailableTime = item;
            this.currentId = item.id;
        },

        removeAllAvailableTimes() {
            AvailableTimeDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentAvailableTime = null;
            this.currentId = -1;
            AvailableTimeDataService.findByName(name)
                .then(response => {
                    this.availableTimes = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentAvailableTime = null;
            this.currentId = -1;
        },
        formatRemainingTime(seconds) {
            if (seconds == 0) return 0;
            const d = Number(seconds);
            const h = Math.floor(d / 3600);
            const m = Math.floor(d % 3600 / 60);
            const s = Math.floor(d % 3600 % 60);

            const hDisplay = h > 0 ? h + (h == 1 ? " ora, " : " ore, ") : "";
            const mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minuti, ") : "";
            const sDisplay = s > 0 ? s + (s == 1 ? " secondo" : " secondi") : "";
            let string = hDisplay + mDisplay + sDisplay;
            //if (m <= 0) string += sDisplay;
            return string;
        },
    },
    mounted() {
        this.retrieveAvailableTimes();
    }
};
</script>


<style>
</style>
